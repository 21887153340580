import {Map, View} from "/node_modules/.vite/deps/ol.js?v=2cbbf92c";
import {Tile as TileLayer, Vector as VectorLayer} from "/node_modules/.vite/deps/ol_layer__js.js?v=2cbbf92c";
import OSM from "/node_modules/.vite/deps/ol_source_OSM.js?v=2cbbf92c";
import {useGeographic} from "/node_modules/.vite/deps/ol_proj__js.js?v=2cbbf92c";
import Feature from "/node_modules/.vite/deps/ol_Feature__js.js?v=2cbbf92c";
import Overlay from "/node_modules/.vite/deps/ol_Overlay__js.js?v=2cbbf92c";
import Point from "/node_modules/.vite/deps/ol_geom_Point__js.js?v=2cbbf92c";
import {Icon, Style} from "/node_modules/.vite/deps/ol_style__js.js?v=2cbbf92c";
import {OGCMapTile, Vector as VectorSource} from "/node_modules/.vite/deps/ol_source__js.js?v=2cbbf92c";
import Fill from "/node_modules/.vite/deps/ol_style_Fill__js.js?v=2cbbf92c";
import Circle from "/node_modules/.vite/deps/ol_style_Circle__js.js?v=2cbbf92c";
import Stroke from "/node_modules/.vite/deps/ol_style_Stroke__js.js?v=2cbbf92c";
import * as bootstrap from "/node_modules/.vite/deps/bootstrap.js?v=2cbbf92c"
import "/node_modules/.vite/deps/jquery.js?v=2cbbf92c";

useGeographic();

// Light and Dark Mode
let black_color = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'black' : 'white';
let white_color = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'white' : 'black';
let invertMap = window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false;


// TEMP Events
let events = [
  {'id': 1, 'name': 'BERGLAN #10', guests: 60, 'event_start': '19.07.2024', 'event_location': '42853 Remscheid', 'lat': 51.1845 ,'lon': 7.17943},
  {'id': 2, 'name': 'Fettelan#30', guests: 40, 'event_start': '19.07.2024', 'event_location': '38350 Helmstedt', 'lat': 52.2364 ,'lon': 10.9847},
];


const markerStyle = new Style({
  image: new Circle({
      radius: 10,
      fill: new Fill({ color: black_color }),
      stroke: new Stroke({ color: black_color, width: 2 })
  })
});

const features = [];
$.each(events, function(key, val) {
  let iconFeature = new Feature({
    geometry: new Point([val.lon, val.lat]),
    name: val.name,
    guests: val.guests,
    event_location: val.event_location
  })
  iconFeature.setStyle(markerStyle);
  features.push(iconFeature)
});

const vectorSource = new VectorSource({
  features
});

const vectorLayer = new VectorLayer({
  source: vectorSource
});

/*
// Markers for Events
const markerStyle = new Style({
  text: new Text({
      text: '\uf111',                         // fa-play, unicode uf3c5
      font: '900 10px "Font Awesome 6 Free"', // font weight must be 900
      scale: 1.5,
      textBaseline: 'bottom',
      fill: new Fill({ color: black_color }),
      stroke: new Stroke({ color: white_color, width: 1 })
  })
});

iconFeature.setStyle(markerStyle);

const vectorSource = new VectorSource({
  features: [iconFeature],
});

const vectorLayer = new VectorLayer({
  source: vectorSource,
});

*/
const tileLayer = new TileLayer({
  source: new OSM(),
})

const map = new Map({
  target: 'map',
  layers: [
   tileLayer,
   vectorLayer
  ],
  view: new View({
    center: [11.401968, 50.570134],
    zoom: 6
  })
});

if(invertMap) {
  //change map color
  map.on('postcompose',function(e){
    document.querySelector('canvas').style.filter="invert(100%)";
  });
}

const element = document.getElementById('popup');

const popup = new Overlay({
  element: element,
  positioning: 'bottom-center',
  stopEvent: false,
});
map.addOverlay(popup);

let popover;
function disposePopover() {
  if (popover) {
    popover.dispose();
    popover = undefined;
  }
}
// display popup on click
map.on('click', function (evt) {
  const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    return feature;
  });
  disposePopover();
  if (!feature) {
    return;
  }
  popup.setPosition(evt.coordinate);
  popover = new bootstrap.Popover(element, {
    placement: 'top',
    html: true,
    content: feature.get('name')+"<br />"+feature.get('event_location')+"<br />Teilnehmer: "+feature.get('guests')
  });
  popover.show();
});

// Close the popup when the map is moved
map.on('movestart', disposePopover);

